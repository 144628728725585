import * as React from "react"
import { HeadFC } from "gatsby"
import { Link } from "gatsby-plugin-intl-v4"
import { graphql } from "gatsby"

const slideImagesStyles = {
  width: '80vw',
  paddingLeft: '10vw',
  paddingRight: '10vw',
  paddingBottom: '64px',
  paddingTop: '70px',
  textAlign: 'center'
}

const imageStyles = {
  maxHeight: '90vh',
  maxWidth: '100%',
  marginBottom: '150px'
}

const SlideImages = ({ images, url }) =>
  <div style={slideImagesStyles}>
    {images?.map((image, index) => {
      if(index == 0) return
      return (
        <Link to={url} state={{ index: index }}>
          <div>
            <img style={imageStyles} src={image.url + '?w=2000&h=1200&fm=jpg&q=60'} />
          </div>
        </Link>
        )
      }
    )}
  </div>

export default SlideImages
