import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl-v4"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import Nav from "../../components/Nav"
import SlideImages from "../../components/SlideImages"
import { GlobalHead } from "../../components/GlobalHead"
import languageFromLocale from "../../helper/language-helper"
import "../../styles.scss"

const Project = ({data }) => {
  const intl = useIntl()
  const project = data.allContentfulProject.nodes
    .find(n => n.node_locale === languageFromLocale(intl.locale))
  const {name, internalName, attributes, mainImage, description, slideImages} = project
  const projectId = internalName?.toLowerCase().replace(/ /g,"-")

  const to = {
     pathname: '/project/' + projectId + '/slide/',
     state: { originProject: true }
   }

  return (
    <div>
      <Nav />
      <div className={'project'}>
        <div className={'project__info'}>
          <div>
            <h1>{name}</h1>
          </div>
          { slideImages?.length > 0 &&
            <p className='project__cta'>
              <Link to={to.pathname} className={'project__slideshow-cta'}>
              {intl.formatMessage({ id: "openSlideshow" })}
              </Link>
            </p>
          }
          { attributes?.length > 0 &&
            <div className={'project__details'}>
              {
                attributes.map(attribute => (
                  <div className={'project__detail'}>
                    <div className={'project__detail__name'}>{ attribute.name }:</div>
                    <div className={'project__detail__value'}>{ attribute.value }</div>
                  </div>
                ))
              }
            </div>
          }
          { mainImage &&
            <div className={'project__main-image__mobile'}>
              <Link to={to.pathname}>
                <img src={ mainImage.url + '?w=1200&h=1200&fm=jpg&q=60' } />
              </Link>
            </div>
          }
        </div>
        { mainImage &&
          <Link to={to.pathname} className={'project__main-image'} style={{'backgroundImage': 'url(' + mainImage.url + '?fit=pad&w=2000&h=1200&fm=jpg&q=45)'}} />
        }
      </div>
      <SlideImages images={slideImages} url={'/project/' + projectId + '/slide'} />

    </div>
  )
}

export const query = graphql`
  query ($internalName: String) {
    allContentfulProject(filter: {internalName: {eq: $internalName}}) {
      nodes {
        createdAt
        contentful_id
        name
        internalName
        node_locale
        description {
          description
          id
        }
        attributes {
          name
          value
        }
        mainImage {
          url
        }
        slideImages {
          url
          id
        }
      }
    }
  }
`;

export default Project

export function Head() {
  return (
    <GlobalHead />
  )
}
